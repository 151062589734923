<template>
    <div>
        <div v-if="show" id="mainGrid" class="p-grid" style="margin:0 5px;position:relative;">
            <TriStateCheckbox id="verifiedCheckbox" v-model="verifiedFilter" style="position:absolute;z-index:150;top:50px;left:12px" />
            <Toolbar id="button-layout" class="flex justify-content-between">
                <template #end>
                    <Button v-if="$store.state.userConfig.dataTableItemsView" label="" icon="pi pi-th-large" @click="photoLayout" />
                    <Button v-if="!$store.state.userConfig.dataTableItemsView" label="" icon="pi pi-bars" @click="withoutPhotoLayout" />
                </template>
            </Toolbar>

            <DataTable v-if="$store.state.userConfig.dataTableItemsView" :value="items"
                       :paginator="true"
                       class="p-col-12 p-datatable-sm"
                       style="padding-top:0px"
                       show-gridlines
                       :rows="10"
                       data-key="id"
                       :loading="loading"
                       responsive-layout="scroll"
                       :row-hover="true"
                       selection-mode="single"
                       @row-select="openItem"
            >
                <template #header>
                    <div class="flex justify-content-between">
                        <Button class="p-input-icon-left" icon="pi pi-camera" @click="cameraOn()" />
                        <span class="p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText v-model="filter" style="width:145px" placeholder="Hledat" />
                        </span>
                        <Button class="p-input-icon-left" icon="pi pi-times" :disabled="filter == null || filter.length == 0" @click="filter = null" />
                        <div v-if="!isCameraOff">
                            <Button class="p-button-warning" label="Vypnout skenování" icon="pi pi-times" @click="cameraOff()" />
                            <web-cam ref="webcam"
                                     :device-id="deviceId"
                                     width="100%"
                                     @started="onStarted"
                                     @stopped="onStopped"
                                     @error="onError"
                                     @cameras="onCameras"
                                     @camera-change="onCameraChange"
                            />
                            <br>
                            <code v-if="device">{{ device.label }}</code>
                            <select v-model="camera">
                                <option>-- Select Device --</option>
                                <option v-for="device in devices"
                                        :key="device.deviceId"
                                        :value="device.deviceId"
                                >
                                    {{ device.label }}
                                </option>
                            </select>
                            <!-- {{ showTextItems }} -->
                        </div>
                    </div>
                </template>

                <template #empty>
                    Žádná data.
                </template>
                <template #loading>
                    Nahrávám ...
                </template>
                <Column field="verified" header="" data-type="boolean" body-class="text-center" :styles="{'width':'auto'}">
                    <template #body="{data}">
                        <i :style="data.verified == 'true' ? 'color:green' : 'color:red'"
                           class="pi"
                           :class="{'true-icon pi-check-circle': data.verified == 'true', 'false-icon pi-times-circle': data.verified != 'true'}"
                        >
                        </i>
                    </template>
                    <!-- <template #filter="{filterModel,filterCallback}">
                        <TriStateCheckbox v-model="filterModel.value" @change="filterCallback()" />
                    </template> -->
                </Column>
                <Column field="ič" header="ič">
                    <template #body="{data}">
                        {{ data.ic }}
                    </template>
                </Column>
                <Column field="name" header="název">
                    <template #body="{data}">
                        {{ data.name }}
                    </template>
                </Column>
            </DataTable>
            <DataView v-if="!$store.state.userConfig.dataTableItemsView"
                      class="p-col-12"
                      style="padding-top:0px"
                      :value="items"
                      :layout="layout"
                      :paginator="true"
                      :rows="10"
                      :sort-order="sortOrder"
                      :sort-field="sortField"
            >
                <template #header>
                    <div class="flex justify-content-between">
                        <Button class="p-input-icon-left" icon="pi pi-camera" @click="cameraOn()" />
                        <span class="p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText v-model="filter" style="width:145px" placeholder="Hledat" />
                        </span>
                        <Button class="p-input-icon-left" icon="pi pi-times" :disabled="filter == null || filter.length == 0" @click="filter = null" />
                        <div v-if="!isCameraOff">
                            <Button class="p-button-warning" label="Vypnout skenování" icon="pi pi-times" @click="cameraOff()" />
                            <web-cam ref="webcam"
                                     :device-id="deviceId"
                                     width="100%"
                                     @started="onStarted"
                                     @stopped="onStopped"
                                     @error="onError"
                                     @cameras="onCameras"
                                     @camera-change="onCameraChange"
                            />
                            <br>
                            <code v-if="device">{{ device.label }}</code>
                            <select v-model="camera">
                                <option>-- Select Device --</option>
                                <option v-for="tmpDevice in devices"
                                        :key="tmpDevice.deviceId"
                                        :value="tmpDevice.deviceId"
                                >
                                    {{ tmpDevice.label }}
                                </option>
                            </select>
                            <!-- {{ showTextItems }} -->
                        </div>
                    </div>
                </template>

                <template #grid="slotProps">
                    <div class="p-col-12 ">
                        <div class="product-list-item" style="display:flex;font-size:10px" @click="openItemId(slotProps.data.id)">
                            <div class="product-list-detail p-col-1" style="display:block">
                                <i :style="slotProps.data.verified == 'true' ? 'color:green' : 'color:red'"
                                   class="pi"
                                   :class="{'true-icon pi-check-circle': slotProps.data.verified == 'true', 'false-icon pi-times-circle': slotProps.data.verified != 'true'}"
                                >
                                </i>
                            </div>
                            <div class="product-list-detail p-col-4" style="display:block">
                                <img :src="dataUrl(slotProps.data)" style="width:80px;heigh:auto;margin:auto">
                                <!-- <div class="product-description">
                                    {{ slotProps.data.description }}
                                </div> -->
                                <!-- <i class="pi pi-tag product-category-icon"></i><span class="product-category">{{ slotProps.data.category }}</span> -->
                            </div>

                            <!-- <div class="product-list-action"> -->
                            <!-- <span class="product-price">${{ slotProps.data.price }}</span> -->
                            <!-- <Button icon="pi pi-shopping-cart" label="Add to Cart" :disabled="slotProps.data.inventoryStatus === 'OUTOFSTOCK'" /> -->
                            <!-- <span :class="'product-badge status-'+slotProps.data.inventoryStatus.toLowerCase()">{{ slotProps.data.inventoryStatus }}</span> -->
                            <!-- </div> -->
                            <div class="product-list-action p-col-7">
                                <div class="product-name" style="font-weight:bold">
                                    {{ slotProps.data.name }}
                                </div>
                                <div class="product-ic">
                                    Identifikační číslo: {{ slotProps.data.ic }}
                                </div>
                                <div class="product-extenze">
                                    Extenze: {{ slotProps.data.parts }}
                                </div>
                                <div class="product-amount">
                                    Počet kusů: {{ slotProps.data.amount }}
                                </div>
                                <div class="product-state">
                                    Stav: <Rating :value="Number(slotProps.data.state)" :readonly="true" :cancel="false" />
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </DataView>
            <Button
                class="p-col-4"
                label="Nový předmět (pro účely testování)"
                icon="pi pi-plus"
                @click="addItem()"
            />
            <div class="p-col-1" />
            <div class="p-col-2">
                <router-link to="/print" target="_blank" class="p-col-12" style="display:block">
                    <Button
                        class="p-col-12 p-button-raised p-button-secondary"
                        label="Protokol"
                    />
                </router-link>
            </div>
            <div class="p-col-1" />
            <Button
                label="Vymazat verifikace (pro účely testování)"
                class="p-col-3 p-button-danger"
                icon="pi pi-trash"
                @click="resetTestData"
            />
        </div>
        <div v-if="$store.state.debug.log">
            <br>
            ITEMS **************************
            <vue-json-pretty :data="items" />
        </div>
    </div>
</template>

<script>
const AWS = require('aws-sdk')
import { StringUtils } from "@/utils/StringUtils";
import { WebCam } from "vue-web-cam";
import { find, head, last } from "lodash";
//import {FilterMatchMode,FilterOperator} from 'primevue/api';

export default {
        components: {
                WebCam
        },
        data() {
                return {
                        verifiedFilter: null,
                        layout: 'grid',
                        filter: null,
                        // filters: {
                        //         'verified': {value: null, matchMode: FilterMatchMode.EQUALS}
                        // },
                        photoLayoutMode:false,
                        autoTakeImage: true,
                        image: null,
                        textItems: [],
                        showTextItems: [],
                        imageBase64: null,
                        isCameraOff: true,
                        checkImage: null,
                        camera: null,
                        deviceId: null,
                        devices: [],
                        countCheckImage: 0,
                        checkImageInterval: 5000,
                        maxCountCheckImage: 15,

                        sortKey: null,
                        sortOrder: null,
                        sortField: null,
                        sortOptions: [
                                {label: 'Náhodně ;)', value: '!price'},
                                {label: 'Jak se mi chce!', value: 'price'},
                        ]
                }
        },
        computed: {
                show() {
                        return true;
                },
                loading() {
                        return !(this.$store.state.items && this.$store.state.items.length > 0)
                },
                items() {
                        let items = this.$store.state.items
                        let result = []
                        if (items) {
                                for (let item of items) {
                                        result.push(item);
                                }
                                result = this.genericFilter(result);
                                result = this.verifyFilter(result);

                                // result.sort(function (a, b) {
                                //         return b.updatedAt.toString().localeCompare(a.updatedAt.toString());
                                // });

                        }
                        return result
                },
                device() {
                        return find(this.devices, n => n.deviceId == this.deviceId);
                },
        },
        watch: {
                textItems() {
                        console.log('ITEMS *****************************')
                        for (let textItem of this.textItems) {
                                let text = textItem.DetectedText
                                console.log('test text: ' + text)
                                let item = this.items.find((x) => x.ic == text)
                                if (item) {
                                        console.log('found item !!!!!!!!!!!!!!!!!!!!!!!')
                                        console.log(item)
                                        this.openItemId(item.id)
                                }
                        }
                },
                countCheckImage(newValue, oldValue) {
                        if (newValue > this.maxCountCheckImage) {
                                this.cameraOff()
                        }
                },
                camera: function(id) {
                        this.deviceId = id;
                },
                devices: function() {
                        // Once we have a list select the first one
                        let tmpDevice = last(this.devices);
                        if (tmpDevice) {
                                this.camera = tmpDevice.deviceId;
                                this.deviceId = tmpDevice.deviceId;
                        }
                }
        },
        created() {
                this.$store.dispatch('fetchItems')
        },
        mounted() {
                this.$store.state.canEdit = false
        },
        beforeDestroy() {
                clearInterval(this.checkImage)
        },
        methods: {
                photoLayout(){
                        this.$store.state.userConfig.dataTableItemsView = false;
                        const x = document.getElementsByClassName("p-column-filter-overlay");
                        if(x[0]){x[0].remove();};
                },
                withoutPhotoLayout(){
                        this.$store.state.userConfig.dataTableItemsView = true;
                        const x = document.getElementsByClassName("p-column-filter-overlay");
                        if(x[0]){x[0].remove();};
                },
                checkImageInput() {
                        let self = this
                        if (this.autoTakeImage) {
                                this.checkImage = setInterval(() => {
                                        this.countCheckImage++
                                        console.log('check')
                                        self.onCapture()
                                }, this.checkImageInterval)
                        }
                },
                cameraOn() {
                        this.countCheckImage = 0
                        this.checkImageInput()
                        this.isCameraOff = false
                },
                cameraOff() {
                        clearInterval(this.checkImage)
                        this.isCameraOff = true
                },
                //Calls DetectText API and shows estimated ages of detected text
                DetectText(imageData) {
                        console.log('detect text')
                        AWS.region = "eu-central-1";
                        var rekognition = new AWS.Rekognition();
                        var params = {
                                Image: {
                                        Bytes: imageData
                                },
                        };
                        let self = this
                        rekognition.detectText(params, function (err, data) {
                                if (err) console.log(err, err.stack); // an error occurred
                                else {
                                        console.log(data)
                                        self.textItems.splice(0, self.textItems.length)
                                        self.textItems.push(...data.TextDetections)
                                        self.showTextItems.splice(0, self.showTextItems.length)
                                        for (let tmp of data.TextDetections) {
                                                self.showTextItems.push(tmp.DetectedText)
                                        }
                                }
                        });
                },
                //Process captured image and unencodes image bytes for Rekognition DetectText API
                ProcessImageCaptured(inputImage) {
                        console.log('process captured image')
                        console.log(inputImage)
                        // if (!this.image || !this.image.length < 100) return
                        // console.log('process captured image')

                        this.AnonLog();

                        // Load base64 encoded image
                        var image = null;
                        // img.src = e.target.result;
                        // self.image = img.src
                        var jpg = true;
                        try {
                                image = atob(inputImage.split("data:image/jpeg;base64,")[1]);

                        } catch (e) {
                                jpg = false;
                        }
                        if (jpg == false) {
                                try {
                                        image = atob(inputImage.split("data:image/png;base64,")[1]);
                                } catch (e) {
                                        console.log("Not an image file Rekognition can process");
                                        return;
                                }
                        }
                        //unencode image bytes for Rekognition DetectText API
                        var length = image.length;
                        let imageBytes = new ArrayBuffer(length);
                        var ua = new Uint8Array(imageBytes);
                        for (var i = 0; i < length; i++) {
                                ua[i] = image.charCodeAt(i);
                        }
                        //Call Rekognition
                        this.DetectText(imageBytes);
                },
                //Loads selected image and unencodes image bytes for Rekognition DetectText API
                ProcessImageLoaded() {
                        console.log('process image')
                        this.AnonLog();
                        var control = document.getElementById("fileToUpload");
                        var file = control.files[0];

                        // Load base64 encoded image
                        var reader = new FileReader();
                        let self = this
                        reader.onload = (function (theFile) {
                                return function (e) {
                                        var img = document.createElement('img');
                                        var image = null;
                                        img.src = e.target.result;
                                        self.image = img.src
                                        var jpg = true;
                                        try {
                                                image = atob(e.target.result.split("data:image/jpeg;base64,")[1]);

                                        } catch (e) {
                                                jpg = false;
                                        }
                                        if (jpg == false) {
                                                try {
                                                        image = atob(e.target.result.split("data:image/png;base64,")[1]);
                                                } catch (e) {
                                                        alert("Not an image file Rekognition can process");
                                                        return;
                                                }
                                        }
                                        //unencode image bytes for Rekognition DetectText API
                                        var length = image.length;
                                        let imageBytes = new ArrayBuffer(length);
                                        var ua = new Uint8Array(imageBytes);
                                        for (var i = 0; i < length; i++) {
                                                ua[i] = image.charCodeAt(i);
                                        }
                                        //Call Rekognition
                                        self.DetectText(imageBytes);
                                };
                        })(file);
                        reader.readAsDataURL(file);
                },
                //Provides anonymous log on to AWS services
                AnonLog() {
                        // Configure the credentials provider to use your identity pool
                        AWS.config.region = 'eu-central-1'; // Region
                        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                                IdentityPoolId: 'eu-central-1:aedab169-3918-4846-9a52-b19a6aceca3b',
                        });
                        // Make the call to obtain credentials
                        AWS.config.credentials.get(function () {
                                // Credentials will be available when this function is called.
                                var accessKeyId = AWS.config.credentials.accessKeyId;
                                var secretAccessKey = AWS.config.credentials.secretAccessKey;
                                var sessionToken = AWS.config.credentials.sessionToken;
                        });
                },
                onCapture() {
                        let tmp = this.$refs.webcam.capture();
                        // this.isCameraOff = true
                        console.log('onCapture')
                        console.log(tmp)
                        this.ProcessImageCaptured(tmp)
                },
                onStarted(stream) {
                        console.log("On Started Event", stream);
                },
                onStopped(stream) {
                        console.log("On Stopped Event", stream);
                },
                onStop() {
                        this.$refs.webcam.stop();
                },
                onStart() {
                        this.$refs.webcam.start();
                },
                onError(error) {
                        console.log("On Error Event", error);
                },
                onCameras(cameras) {
                        this.devices = cameras;
                        console.log("On Cameras Event", cameras);
                },
                onCameraChange(deviceId) {
                        this.deviceId = deviceId;
                        this.camera = deviceId;
                        console.log("On Camera Change Event", deviceId);
                },
                genericFilter(items) {
                        let filter = this.filter
                        if (!filter || !items) return items;
                        filter = StringUtils.normalize(filter);
                        let filters = filter.split(" ");
                        let result = [];
                        for (let item of items) {
                                let add = true;
                                for (let s of filters) {
                                        if (s) {
                                                s = s.trim();
                                        }
                                        if (item.ic && StringUtils.normalize(item.ic).includes(s)) continue;
                                        if (item.name && StringUtils.normalize(item.name).includes(s)) continue;
                                        add = false;
                                        break;
                                }
                                if (add) {
                                        result.push(item);
                                }
                        }
                        return result;
                },
                verifyFilter(items) {
                        let verifiedFilter = this.verifiedFilter;
                        if(verifiedFilter == null ) return items;
                        if (!items) return items;
                        let result = [];
                        for (let item of items) {
                                if (item.verified == String(verifiedFilter)){
                                        result.push(item);
                                };
                        }
                        return result;
                },
                dataUrl(item) {
                        if (item && item.image) {
                                // return 'data:image/png;base64,' + item.image;
                                return item.image;
                        }
                },
                addItem() {
                        this.$store.dispatch("addItem").then((res) => {
                                this.$router.push({
                                        name: "item",
                                        params: { id: res.id, isNew: true },
                                });
                        });
                },
                onSortChange(event){
                        const value = event.value.value;
                        const sortValue = event.value;

                        if (value.indexOf('!') === 0) {
                                this.sortOrder = -1;
                                this.sortField = value.substring(1, value.length);
                                this.sortKey = sortValue;
                        }
                        else {
                                this.sortOrder = 1;
                                this.sortField = value;
                                this.sortKey = sortValue;
                        }
                },
                openItem(event) {
                        this.openItemId(event.data.id)
                },
                openItemId(id) {
                        this.$router.push('/item/' + id)
                },
                resetTestData() {
                        for (let item of this.items) {
                                item.verified = 'false'
                                this.$store.dispatch('updateItem', { item: item, isValid: item.isValid })
                        }
                }
        },
};
</script>

<style>
.p-dataview .p-dataview-header,.p-datatable.p-datatable-sm .p-datatable-header {
    width: 275px;
    background-color: transparent;
    border: 0px;
    padding: 0px 8px 8px 45px;
}
div#button-layout {
    width: 40px;
    padding: 0;
    position: absolute;
    right: 14px;
    top: 0px;
    background-color: transparent;
    z-index: 10000000;
    padding-left: 0;
    border: 0px;
}
.product-name {
    font-size: 14px;
}
span.p-checkbox-icon.pi.pi-times{
    color: #c02929;
    background-color: white;
}
span.p-checkbox-icon.pi.pi-check{
        color: #689F38;
        background-color: white;
}
div#mainGrid > .p-checkbox.p-component{
        width: 37px;
        height: 39px;
}
div#mainGrid > .p-checkbox.p-component .p-checkbox-box{
        width: 37px;
        height: 39px;
        border-color: #2196F3;
}
</style>
